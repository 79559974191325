export const SEAT_SET_AIRLINE_ONWARD = "SEAT_SET_AIRLINE_ONWARD ";
export const NUMBER_OF_AIRLINE_ONWARD = "NUMBER_OF_AIRLINE_ONWARD ";
export const CLEAR_SEAT_AIRRLINE_ONWARD = "CLEAR_SEAT_AIRRLINE_ONWARD ";
export const CLEAR_ALL_AIRRLINE_ONWARD = "CLEAR_ALL_AIRRLINE_ONWARD ";
export const AMOUNT_SET_AIRLINE_ONWARD = "AMOUNT_SET_AIRLINE_ONWARD ";
export const DEFAULT_SEAT_OCCUPATION_ONWARD = "DEFAULT_SEAT_OCCUPATION_ONWARD ";
export const SEAT_SET_MID_AMOUNT_ONWARD = "SEAT_SET_MID_AMOUNT_ONWARD ";
export const SEAT_SET_AMOUNT_TVO_ONWARD = "SEAT_SET_AMOUNT_TVO_ONWARD ";

export const SEAT_SET_AIRLINE_RETURN = "SEAT_SET_AIRLINE_RETURN ";
export const NUMBER_OF_AIRLINE_RETURN = "NUMBER_OF_AIRLINE_RETURN ";
export const CLEAR_SEAT_AIRRLINE_RETURN = "CLEAR_SEAT_AIRRLINE_RETURN ";
export const CLEAR_ALL_AIRRLINE_RETURN = "CLEAR_ALL_AIRRLINE_RETURN ";
export const AMOUNT_SET_AIRLINE_RETURN = "AMOUNT_SET_AIRLINE_RETURN ";
export const DEFAULT_SEAT_OCCUPATION_RETURN = "DEFAULT_SEAT_OCCUPATION_RETURN ";
export const SEAT_SET_MID_AMOUNT_RETURN = "SEAT_SET_MID_AMOUNT_RETURN ";
export const SEAT_SET_AMOUNT_TVO_RETURN = "SEAT_SET_AMOUNT_TVO_RETURN ";
export const SSR_REQUET_ONWARD = "REQUEST_SSR_ONWARD";
export const SSR_REQUEST_RETURN = "SSR_REQUEST_RETURN";
export const SSR_SUCCESS_ONWARD = "SSR_SUCCESS_ONWARD";
export const SSR_SUCCESS_RETURN = "SSR_SUCCESS_RETURN";
export const SSR_FAIL_ONWARD = "REQUEST_FAIL_RETURN";
export const SSR_FAIL_RETURN = "REQUEST_FAIL_RETURN";
export const MEAL_SET_AIRLINE_ONWARD = "MEAL_SET_AIRLINE_ONWARD";
export const MEAL_SET_AIRLINE_RETURN = "MEAL_SET_AIRLINE_RETURN";
export const BAGGAGE_SET_AIRLINE_ONWARD = "BAGGAGE_SET_AIRLINE_ONWARD";
export const BAGGAGE_SET_AIRLINE_RETURN = "BAGGAGE_SET_AIRLINE_RETURN";
