export const RETURN_REQUEST = "RETURN_REQUEST";
export const RETURN_SUCCESS = "RETURN_SUCCESS";
export const RETURN_SEARCH_AMD = "RETURN_SEARCH_AMD";
export const RETURN_SEARCH_TBO_KAFILA = "RETURN_SEARCH_AMD_KAFILA";

export const CLEAR_RETURN_REDUCER = "CLEAR_RETURN_REDUCER";
export const AMADEUS_SEARCH_RETURN_REQUEST = 'AMADEUS_SEARCH_RETURN_REQUEST';
export const AMADEUS_SEARCH_RETURN_SUCCESS = 'AMADEUS_SEARCH_RETURN_SUCCESS';
export const AMADEUS_SEARCH_RETURN_FAILURE = 'AMADEUS_SEARCH_RETURN_FAILURE';
export const TBO_KAFILA_SEARCH_RETURN_REQUEST = 'TBO_KAFILA_SEARCH_RETURN_REQUEST';
export const TBO_KAFILA_SEARCH_RETURN_SUCCESS = 'TBO_KAFILA_SEARCH_RETURN_SUCCESS';
export const TBO_KAFILA_SEARCH_RETURN_FAILURE = 'TBO_KAFILA_SEARCH_RETURN_FAILURE';
